.footer {
  background-color: var(--secondary-color);
  position: absolute;
  right: 0;
  left: 0;
  bottom: auto;
  overflow: hidden;
  padding: 65% 15% 10% 15%;
  color: var(--helper-color);
  margin-top: 0%;
}

.footer-padding {
  margin: 8% 0;
}

.footer-logo {
  width: 60%;
}

.footer-imagen {
  width: 145%;
}

.footer-space {
  padding: 10% 0;
}

.footer-title {
  font-size: 11px;
  line-height: 15px;
}
.footer-data {
  font-size: 14px;
  line-height: 19px;
}

.footer-privacity {
  padding: 14% 5%;
}

.footer-date {
  font-size: 10px;
  line-height: 14px;
}

@media (min-width: 415px) {
  .footer {
    margin-top: 0;
    padding: 56% 15% 10% 15%;
  }

  .footer-logo {
    width: 30%;
  }

  .footer-imagen {
    width: 115%;
  }

  .footer-title {
    font-size: 8px;
    line-height: 11px;
  }

  .footer-data {
    font-size: 9px;
    line-height: 12px;
  }

  .footer-privacity {
    padding: 4% 5%;
    font-size: 9px;
    line-height: 20px;
  }

  .footer-padding {
    margin: 20% 0;
  }
}

@media (min-width: 900px) {
  .footer {
    margin-top: 0;
    padding: 26% 0% 10% 13%;
  }
  .footer-logo {
    width: 25%;
  }

  .footer-imagen {
    width: 70%;
  }

  .footer-title {
    font-size: 1.2vw;
    line-height: 2vw;
  }

  .footer-data {
    font-size: 1.2vw;
    line-height: 1.5vw;
  }

  .footer-privacity {
    position: absolute;
    margin-left: 50vw;
    padding: 4% 15% 10%;
    font-size: 1.2vw;
    line-height: 40px;
  }

  .footer-padding {
    margin: 5% 0;
  }
}

@media (min-width: 1500px) {
  .footer {
    margin-top: 0;
    padding: 26% 0% 10% 13%;
  }
  .footer-logo {
    width: 25%;
  }

  .footer-imagen {
    width: 70%;
  }

  .footer-title {
    font-size: 1.2vw;
    line-height: 2vw;
  }

  .footer-data {
    font-size: 1.2vw;
    line-height: 1.5vw;
  }

  .footer-privacity {
    position: absolute;
    margin-left: 50vw;
    padding: 4% 15% 10%;
    font-size: 1.2vw;
    line-height: 40px;
  }

  .footer-padding {
    margin: 5% 0;
  }
}