@font-face {
  font-family: 'avenir';
  src: url('../src/components/assets/fonts/avenirnext-ultralight-11-webfont.woff') format('woff');
  font-weight: 200;
  font-style: thin;
}

@font-face {
  font-family: 'avenir';
  src: url('../src/components/assets/fonts/avenirnext-regular-08-webfont.woff') format('woff');
  font-weight: 400;
  font-style: regular;
}

@font-face {
  font-family: 'avenir';
  src: url('../src/components/assets/fonts/avenirnext-medium-06-webfont.woff') format('woff');
  font-weight: 600;
  font-style: demibold;
}
@font-face {
  font-family: 'avenir';
  src: url('../src/components/assets/fonts/avenirnext-heavy-09-webfont.woff') format('woff');
  font-weight: 800;
  font-style: bold;
}
@font-face {
  font-family: 'avenir';
  src: url('../src/components/assets/fonts/avenirnext-bold-01-webfont.woff') format('woff');
  font-weight: 900;
  font-style: bold;
}

body {
  margin: 0;
  font-family: 'avenir' !important;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6!important;
}

p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

code {
  font-family: 'avenir';
  /* font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
}
