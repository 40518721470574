.services-component {
  padding: 20% 0;
}

.sc-columns {
  padding: 10% 6%;
}

.sc-columns-title {
  font-size: 5vw;
  line-height: 5vw;
  letter-spacing: 1.6px;
  padding-bottom: 8%;
  margin-left: 3vw;
}

.sc-subtitle {
  margin-left: 3vw;
  font-size: 4vw;
  line-height: 5vw;
  letter-spacing: 1.4px;
  padding-bottom: 8%;
}

@media (min-width: 400px) {
  .sc-columns-title {
    margin-left: 3vw;
    font-size: 4vw;
    line-height: 3.5vw;
    letter-spacing: 1.2px;
    padding-bottom: 8%;
  }
  
  .sc-subtitle {
    margin-left: 3vw;
    font-size: 4vw;
    line-height: 4.5vw;
    letter-spacing: 1.1px;
    padding-bottom: 8%;
  }
  .services-component {
    padding: 10% 0 0;
}
}

@media (min-width: 576px) {
  .sc-columns-title {
    margin-left: 3vw;
    font-size: 2.5vw;
    line-height: 2.5vw;
    letter-spacing: 1.2px;
    padding-bottom: 8%;
  }
  
  .sc-subtitle {
    margin-left: 3vw;
    font-size: 2.7vw;
    line-height: 3.5vw;
    letter-spacing: 1.1px;
    padding-bottom: 8%;
  }
  .services-component {
    padding: 10% 0 0;
}
}
@media (min-width: 900px) {
  .sc-columns-title {
    font-size: 2.1vw;
    line-height: 3vw;
    letter-spacing: 2px;
    padding-bottom: 10%;
  }
  
  .sc-subtitle {
    font-size: 2vw;
    line-height: 3.3vw;
    letter-spacing:1.6px;
    padding-bottom: 10%;
  }

  .services-component {
    padding: 20% 0 0;
}
}
@media (min-width: 1500px) {
  .services-component {
    padding: 20% 0 0;
  }

  .sc-columns-title {
    font-size: 1.9vw;
    line-height: 3vw;
    letter-spacing: 3px;
    padding-bottom: 13%;
  }
  
  .sc-subtitle {
    font-size: 1.4vw;
    line-height: 2vw;
    letter-spacing: 2.4px;
    padding-bottom: 8%;
  }
}