.ourModel {
  background-color: #f6f6f6;
  padding: 8% 13%;
  width: 90vw;
  margin-left: -10vw;
  position: relative;
  bottom: auto;
  left: 0;
  right: 0;
}

.ourModel-padding {
  padding: 15px;
}

.om-number {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 2px;
  top: -3px;
  position: relative;
}

.om-description {
  font-size:4vw;
  line-height: 20px;
  letter-spacing: 1.4px;
  text-align: left;
}

@media (min-width: 400px) {
  .om-number {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
  
  .om-description {
    font-size: 3vw;
    line-height: 4vw;
  }
}
@media (max-width: 901px) {
  #home .ourModel {
    position: relative;
  }
}
@media (min-width: 900px) {
  .om-number {
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 2px;
  }
  
  .om-description {
    font-size: 1.6vw;
    line-height: 2.5vw;
  }
}
@media (min-width: 1500px) {
  .om-number {
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 3px;
  }
  
  .om-description {
    font-size: 1.2vw;
    line-height: 2vw;
  }
}