.sidebar {
  background: #f7f7f7;
  width: 7.1vw;
  height: 100vh;
  max-height: 450px;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  display: none;
}
.lateralLogo {
  height: 2vw;
  position: absolute;
  transform: rotate(-90deg);
  top: calc(50% - 8px);
}

.sidebar-opened {
  color: var(--helper-color);
  height: 100%;
}

.sidebar-padding {
  /* background-color: black !important; */
  box-shadow: 0px -5px 15px 0px rgba(0,0,0,0.97);
  -webkit-box-shadow: 0px -5px 15px 0px rgba(0,0,0,0.97);
  -moz-box-shadow: 0px -5px 15px 0px rgba(0,0,0,0.97);
  padding: 3% 8% 3%;
}

.sidebar-logo-width {
  width: 65%;
}

.sidebar-columnLogo {
  transform: rotate(-90deg);
  width: 100%;
}

.navbar-body {
  color: var(--helper-color);
}

.sidebar-servicesOpened {
  height: 150px;
}

/** Bootstrap **/
.navbar-light .navbar-toggler {
  border-color: transparent;
}

.btn-close {
  background: none;
}

.dropdown-item {
  color: var(--helper-color);
}

.dropdown-menu.show {
  background-color: var(--secondary-color);
  border: transparent;
}

.offcanvas {
  --bs-offcanvas-width: 75%;
  background-color: var(--secondary-color);
}

.offcanvas-title {
  display: flex;
  justify-content: space-between;
  color: var(--helper-color);
}

.accordion-button {
  background-color: var(--secondary-color);
  padding: 0;
}

.accordion {
  --bs-accordion-bg: var(--secondary-color);
  --bs-accordion-border-color: var(--secondary-color);
  --bs-accordion-active-color: var(--helper-color);
  --bs-accordion-active-bg: var(--helper-color);
  --bs-accordion-btn-color: var(--helper-color);
  --bs-accordion-btn-active-icon: url("../assets/img/cerrar_blanco.svg");
  --bs-accordion-btn-focus-border-color: #fff;
}

.accordion-body {
  color:#fff
}

.accordion-button {
  width: 30%;
}
.accordion-button:not(.collapsed) {
  background-color: var(--secondary-color);
}

.accordion-button:focus {
  border-color: var(--secondary-color);
  box-shadow: none;
}

/* .offcanvas-header {
  background-color: var(--secondary-color);
}

.offcanvas-body{
  background-color: var(--secondary-color);
}

.offcanvas-backdrop {
  background-color: rgba(0, 0, 0, 0.3);
} */

.list-group {
  --bs-list-group-bg: var(--secondary-color);
  --bs-list-group-border-color: rgba(0, 0, 0, 0);
}

.navbar-nav {
  padding: 5% 10% !important;
}



@media (min-width: 415px) {
  .sidebar {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 4%;
    z-index: 3;
  }

  .navbar {
    display: none;
  }

  .sidebar-burger {
    
    width: 85%;
    padding: 14px 20%;
    display: flex;
    justify-content: center;
  }

  .sidebar-burger:focus,
  .sidebar-burger:active,
  .sidebar-burger:hover {
    border: none;
    background-color: #dedede;
  }
  

  .sidebar-medium-padding {
    padding: 8% 8%;
    overflow-y: scroll;
   height: 85%;
  }

  .sidebar-container_links-left {
    padding-left: 19%;
    font-size: 20px;
    line-height: 24px;
  }

  /** boostrap styles **/
  .accordion {
    padding: 5% 0 !important;
  }

  .accordion-button {
    font-size: 3.3vw;
  }

  .nav-link {
    line-height: 3vw;
    padding: 5% 0 !important;
  }

  .btn-close::after {
    /* content: url("../assets/img/cerrar_blanco.svg"); */
    display: block;
    content: "";
    background: transparent url("../assets/img/cerrar_blanco.svg") no-repeat;
    width: 50px;
    height: 100px;
  }
}

@media (min-width: 900px) {
  .sidebar {
    max-height: 90vh;

  }
  .navbar {
    display: none;
  }

  .accordion-button {
    font-size: 2.4vw;
  }

  .sidebar-container_links-left {
    padding-left: 19%;
    font-size: 2.4vw;
    line-height: 24px;
  }

  .sidebar-opened {
    color: var(--helper-color);
    height: 100%;
    width: 40vw !important;
  }
  
}


@media (min-width: 1500px) {
  .sidebar {
    max-height: 90vh;

  }
  .navbar {
    display: none;
  }

  .accordion-button {
    font-size: 2vw;
  }

  .sidebar-container_links-left {
    padding-left: 19%;
    font-size: 2vw;
    line-height: 24px;
  }

  .sidebar-opened {
    color: var(--helper-color);
    height: 100%;
    width: 40vw !important;
  }
  
}

