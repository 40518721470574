:root {
  --principal-color: #f15223;
  --secondary-color: #707070;
  --helper-color: #fff;
  --font-xs: 0.625rem; /* 11px */
  --font-s: 0.875rem; /* 14px */
  --font-m: 1.25rem; /* 20px */
  --font-l: 1.563rem; /* 25px */
}

.App {
  background-color: #f6f6f6;
  padding: 4.5% 4vw 0 0;
}

.container {
  background-color: #fff;
  max-width: 85% !important;
  padding: 8% 7vw;
}

.font-xs {
  font-size: var(--font-xs);
}

.font-s {
  font-size: var(--font-s);
}

.font-m {
  font-size: var(--font-m);
}

.font-l {
  font-size: var(--font-l);
}

.bold {
  font-weight: 900;
}

.color-font {
  color: var(--principal-color);
}

.contrast-color {
  color: var(--helper-color);
}

.secondary-color {
  color: var(--secondary-color);
}

.flex {
  display: flex;
}

.flex-center-vertical {
  display: flex;
  align-items: center;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.scroll-image {
  z-index: 9;
  position: fixed;
  top: 65%;
  right: 0%;
  width: 10%;
}

.margin-rigth-xs {
  margin-right: 0.25rem;
}

.dm-title_img {
  width: 10%;
}

.dm-title-padding_rigth {
  padding-right: 8%;
}

.dm-title-margin_bottom {
  margin-bottom: 10%;
}

.dm-column_title {
  font-size: 6vw;
  line-height: 8vw;
  letter-spacing: 3.5px;
  text-transform: uppercase;
}

.dm-column_description {
  font-size: 5vw;
  line-height: 6vw;
  letter-spacing: 1.8px;
  padding: 10% 0 15%;
}

.dm-marginTop-space {
  margin-top: 85%;
}

.dm-columns-padding {
  padding-bottom: 15%;
}

/* .dm-columns-img {
  width: 10%;
  padding-bottom: 8%;
}

.dm-columns-title {
  font-size: 18px;
  font-weight: 900;
  line-height: 25px;
  padding: 2% 0 5% 0;
}

.dm-columns-subtitle {
  font-size: 14px;
  line-height: 20px;
  color: #bebebe;
} */

.dm-columns-img {
  width: 15%;
  padding-bottom: 6%;
}

.dm-columns-title {
  position: absolute;
  margin-left: 20vw;
  margin-top: -16vw !important;
  font-size: 4vw;
  font-weight: 900;
  line-height: 5.5vw;
  padding: 2% 0 5% 0;
}

.dm-columns-subtitle {
  margin-left: 20vw;
  font-size: 14px;
  line-height: 20px;
  color: #bebebe;
}


.dsa-column_title {
  font-size: 30px;
  line-height: 41px;
  letter-spacing: 3px;
  text-transform: uppercase;
}
.dsa-column_description{
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1.8px;
  padding: 15% 0;
}
.dsa-space-margin_top {
  margin-top: 70%;
}

.dsa-columns-image {
 width: 15%;
}

.dsa-columns-title {
  padding: 10% 3% 10%;
  font-size: 4vw;
  line-height: 25px;
  font-weight: 900;
}

.dsa-columns-text {
  font-size: 3vw;
  line-height: 3.3vw;
  width: 20vw;
  color: var(--secondary-color);
  padding: 5% 0 10% 0;
}

.separacion{
  margin-top: 1vw;
  width: 70vw;
  margin-left: -1vw;
  /* background-color: #f15223; */
  
  border: 1px dotted #f15223;
}

.dv-title-padding_rigth {
  padding-right: 5%;
}

.dv-title_img {
  width: 10%;
}

.dv-title {
  font-size: 25px;
  line-height: 34px;
  letter-spacing: 2.5px;
  text-transform: uppercase;
}

.dv-subtitle {
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 1.8px;
  padding: 15% 0;
}

.dv-space-margin_bottom {
  margin-bottom: 10%;
}

.dv-space-columns {
  margin-top: 75%;
  margin-bottom: 15%;
}

.dv-custom_dash-img {
  width: 15%;
}

.dv-custom_dash-title {
  position: absolute;
  margin-left: 20vw;
  margin-top: -17vw !important;
  font-size: 18px;
  line-height: 25px;
  padding: 8% 0;
}

.dv-custom_dash-subtitle {
  margin-top: 4vw !important;
  font-size: 14px;
  position: absolute;
  margin-left: 20vw;
  line-height: 20px;
  color: #bebebe;
  padding-bottom: 10%;
}

.imageVisual{
  margin-top: 40vw;
}

.dl-space-title {
  padding: 15% 0;
}

.dl-space-margin_top {
  margin-top: 100%;
}

.dl-subtitle-text {
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 1.4px;
  text-align: justify;
}

.dl-subtitle-db {
  padding: 10% 5%;
  font-size: 11px;
  line-height: 15px;
  letter-spacing: 1.1px;
}

.dl-elements-space {
  padding: 22% 0;
}

.dl-title-lists {
  font-size: 25px;
  line-height: 32px;
  font-weight: 600;
  text-transform: uppercase;
  padding: 5%;
}

.dl-number {
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 2px;
}
.dl-number-title {
  font-size: 15px;
  line-height: 20px;
  letter-spacing: 1.5px;
}

.dl-number-description {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1.4px;
}

.contact-title {
  font-size: 20px;
  line-height: 35px;
  letter-spacing: 2px;
  text-transform: uppercase;
  padding: 18% 0;
}

.contact-input-message {
  border: none;
  height: 20vw;
  border-bottom: 1px solid;
  background: rgba(4, 4, 21, 0.03);
  margin: 2% 0;
}

.contact-input {
  height: 2vw;
  border: none;
  border-bottom: 1px solid;
  background: rgba(4, 4, 21, 0.03);
  margin: 2% 0;
}

.contact-input[type=text]{
  font-family: "Avenir Next Medium";
   font-size: 3vw;
  width: 100%;
   padding: 12px 20px;
   margin: 8px 0;
   box-sizing: border-box;
}

.contact-input-message[type=text]{
font-family: "Avenir Next Medium";
 font-size: 3vw;
width: 100%;
 padding: 12px 20px;
 margin: 8px 0;
 box-sizing: border-box;
}

.contact-button-width {
  width: 30%;
  background-color: #f15223;
  border-color: #f15223;
  border-radius: 0;
}

.contact-description {
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 1.8px;
  padding-bottom: 12%;
}

.titleHeader-padding {
  padding-bottom: 15%;
  padding-top: 15%;
}

.titleHeader-img {
  width: 60%;
}

.titleHeader-background {
  background-color: var(--principal-color);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.titleHeader-link {
  color: var(--helper-color);
  text-decoration: none;
  font-size: 15px;
  width: fit-content;
  line-height: 17px;
  letter-spacing: 1.5px;
}

.titleHeader-contac {
  display: none!important;
  
}
.titleHeader-contac-block {
  display: flex !important;
  margin-bottom: 30%;
  width: fit-content;
  padding: 5px 10px;
}

.title-columns {
  text-align: center;
  font-size: 6vw;
  line-height: 7.5vw;
  font-weight: 400;
  text-transform: uppercase;
}

.padding-vertical {
  padding: 10% 0;
}

.home-padding {
  padding: 5% 0 10% 0;  
}

.home-paddingBottom {
  padding-bottom: 25%;
}
/* 
.home-columns-two_title {
  padding-top: 8%;
  padding-right: 20px;
  font-size: 14px;
  line-height: 18px;
}

.home-columns-two_title_bottom {
  padding-bottom: 10%;
  font-size: 14px;
  line-height: 18px;
}

.home-columns-title {
  padding-top: 8%;
  padding-bottom: 10%;
  font-size: 18px;
  line-height: 25px;
}


.home-columns-description {
  font-size: 14px;
  line-height: 20px;
  color: #bebebe
} */



.home-columns-two_title {
  position: absolute;
  margin-left: 20vw;
  margin-top: -12vw !important;
  font-size: 4vw;
  line-height: 18px;
}

.home-columns-two_title_bottom {
  position: absolute;
  margin-left: 20vw;
  margin-top: -7vw !important;
  margin-top: 5vw;
  font-size: 4vw;
  line-height: 18px;
}

.home-columns-title {
  position: absolute;
  margin-left: 20vw;
  margin-top: -13vw !important;
  font-size: 4vw;
  width: 4vw;
  line-height: 5vw;
}

.home-columns-description {
  margin-left: 20vw;
  font-size: 3vw;
  margin-top: 1vw !important;
  line-height: 4.5vw;
  color: #bebebe
}


.home-columns-images {
  width: 20%;
}

.home-columns-paddingBottom {
  padding-bottom: 15%;
}

.title-background-orange {
  background-color: var(--principal-color);
  color: #fff;
  font-size: 1.2vw;
  padding: 3px 7px;
  line-height: 23px;
  letter-spacing: 1.7px;
  text-transform: uppercase;
  font-weight: 600;
  /* width: fit-content; */
}
.text-background-orange {
  background-color: var(--principal-color);
  color: #fff;
  font-size: 11px;
  font-weight: 600;
  padding: 20px 42px;
  text-align: right;
  line-height: 18px;
  letter-spacing: 1.1px;
  text-align: center;
}

.container-bar-orange {
  position: absolute;
  overflow: hidden;
  left: 0%;
  right: 0%;
  top: auto;
}
.bg-svg-1 {
  position: absolute;
  width: 50vw;
  height: 55vw;
  left: 0;
  margin-top: -22%;
  background-size: 250%;
  background-position: 20% center;
  opacity: .3;
  z-index: 3;
  background-repeat: no-repeat;
  pointer-events: none;
}
.bg-svg-2 {
  position: absolute;
  width: 50vw;
  height: 55vw;
  right: 0;
  margin-top: -22%;
  background-size: 250%;
  background-position: 80% center;
  opacity: .3;
  z-index: 1;
  background-repeat: no-repeat;
  pointer-events: none;

}

.title-section-divider {
  margin-top: 7vw;
  padding: 0% 0 0%;
  border-left: 1px solid var(--principal-color);
  padding-left: 1.2vw;
  line-height: 10.35vw;
  letter-spacing: 2px;
  font-weight: 600;
}

.title-section {
  color: var(--principal-color);
  font-size: 5vw;
  line-height: 27px;
  letter-spacing: 2px;
}

.home-description-section {
  text-transform: uppercase;
  font-size: 3vw;
  font-weight: 400;
  line-height: 4vw;
  letter-spacing: 1.6px;
  padding-top: 1rem;
}

.description-section {
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.375rem;
  letter-spacing: 1.6px;
  padding-top: 1rem;
}

.services-title-up {
  font-size: 25px;
  padding: 8% 5% 0 0%;
  line-height: 32px;
}

.services-title {
  font-size: 25px;
  padding: 0% 5% 0 0%;
  line-height: 32px;
}

.services-subtitle {
  font-size: 14px;
  letter-spacing: 1.4px;
  line-height: 19px;
  padding-top: 10%;
  padding-bottom: 10%;
}

.services-columns-padding {
  padding: 8% 0 0 3%;
}



.home-columns-two_title {
  position: absolute;
  margin-left: 20vw;
  margin-top: -12vw !important;
  font-size: 4vw;
  line-height: 18px;
}

.home-columns-two_title_bottom {
  position: absolute;
  margin-left: 20vw;
  margin-top: -7vw !important;
  margin-top: 5vw;
  font-size: 4vw;
  line-height: 18px;
}

.home-columns-title {
  position: absolute;
  margin-left: 20vw;
  margin-top: -13vw !important;
  font-size: 4vw;
  width: 4vw;
  line-height: 5vw;
}

.home-columns-description {
  margin-left: 20vw;
  font-size: 3vw;
  margin-top: 1vw !important;
  line-height: 4.5vw;
  color: #bebebe
}


.home-columns-images {
  width: 20%;
}


.services-columnTitle {
  position: absolute;
  margin-left: 20vw;
  width: 30vw;
  margin-top: -9vw !important;
  font-size: 3.7vw;
  line-height: vw;
  font-weight: 900;
  text-transform: uppercase;
}

.services-columnSubtitle {
  margin-left: 20vw;
  font-size: 3vw;
  margin-top: 3vw !important;
  line-height: 4.5vw;
  color: var(--secondary-color);
}

/* .services-columnTitle-margin {
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 1.6px;
} */

.services-columns-paddingBottom {
  padding-bottom: 10%;
}

.services-link {
  margin-left: 20vw;
  color: var(--principal-color);
  text-decoration: none;
  font-size: 3vw;
}

.clients {
  padding: 14% 0 0% 0;;
}

.clients-brands-margin {
  /* margin: 10% 0; */
  align-items: center;
}



.clients-image {
  width: 70%;
}

.upperCase-font {
  text-transform: uppercase;
}

.marginTop-space {
  margin-top: 90%;
  /* margin-top: 35%; */
}

.conect {
  position: absolute;
  bottom: auto;
  z-index: 1;
  left: 15vw;
  right: 20vw;
}

.conect-background {
  background-color: var(--principal-color);
  padding: 9% 5%;
}
.conect-content {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.conect-text {
  font-size: 15px;
  line-height: 23px;
  text-transform: uppercase;
}

.conect-text-bold {
  font-size: 25px;
  line-height: 23px;
  letter-spacing: 1.25px;
  text-transform: uppercase;
}

.conect-text-description {
  font-size: 12px;
  line-height: 18px;
  padding: 10% 0 3% 0;
}

.conect-button-width {
  width: 100%;
  font-size: 10px;
  line-height: 8px;
  letter-spacing: 0.3px;
  border-radius: 0 !important;
}

.conect-input-margins {
  margin: 6% 0;
}
#about {
  z-index: 3;
  position: relative;
}
.about-bg {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0vw;
  left: 0;
  background-position: center;
  background-size: 140%;
  background-repeat: no-repeat;
  opacity: .3;
  z-index: 2;
}
.aboutUs-paddingTop {
  padding-top: 1.25rem;
}

.aboutUs-paddingBottom {
  padding-bottom: 10%;
}

.paragraph-marginBottom {
  margin-bottom: 2% !important;
}

/** Bootstrap styles **/
.btn-light {
  --bs-btn-bg: transparent !important;
  --bs-btn-border-color: transparent !important;
}

.btn-primary {
  --bs-btn-bg: #111 !important;
  --bs-btn-border-color: #111 !important;
  --bs-btn-hover-bg: var(--secondary-color) !important;
  --bs-btn-hover-border-color: var(--secondary-color) !important;
  --bs-btn-active-bg: var(--principal-color);
  --bs-btn-active-border-color: var(--principal-color);
}

.form-control {
  border-radius: 0 !important;
  border: none !important;
}

.form-control::placeholder {
  font-size: 10px !important;
  line-height: 10px;
  letter-spacing: -0.11px;
  color: #acacac;
}
#visualization .ourModel ,
#management .ourModel  {
  position: relative;
}

.imageVisualGif{
  z-index: 3;
  margin-bottom: 10vw;
  width: 68vw;
}
.text-orange{
  width: 77vw;
  margin-left: 0vw;
}

.marginItems{
  height: 60vw;
}

.agroupItemsScience{
  margin-top: 2vw;
  padding: 0% 0% 0%;
}

.containerItemsScience{
  padding: 4% 10% 10%;
  display: grid;
  grid-template-columns: 35vw 40vw;
}

.ItemsScienceTitle{
  margin-top: 5vw;
  font-size: 4vw;
}

.marginItemsFinal{
  height: 7vw;
}
















































@media (min-width: 415px) {
  .title-background-orange {
    font-size: 2.5vw;
    line-height: 33px;
    letter-spacing: 2.4px;
    margin-right: 20%;
  }

  .scroll-image {
    position: fixed;
    top: 50%;
    right: 3%;
    width: 4%;
    height: 4vw;
  }

  .titleHeader-contac {
    display: flex !important;
    width: 18vw;
    margin-top: -1vw;
    margin-left: -5vw;

  }
  .titleHeader-contac-block {
  display: none !important;
  }

  .padding-vertical {
    padding: 0% 0;
  }

  .titleHeader-img {
    width: 40%;
  }

  .titleHeader-padding {
    padding-bottom: 15%;
    padding-top: 0%;
  }

  .titleHeader-background {
    background-color: var(--principal-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 4px 5px;
    width: fit-content;
  }

  .titleHeader-link {
    color: var(--helper-color);
    text-decoration: none;
    font-size: 9px;
    width: fit-content;
    line-height: 12px;
    letter-spacing: 0.9px;
  }

  .home-description-section {
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: 1.5px;
    padding-top: 5rem;
    width: 70vw;
  }

  .home-paddingBottom {
    padding-bottom: 17%;
    /* margin-left: 1.5vw; */
  }

  .home-columns-two_title {
    position: absolute;
    margin-left: 20vw;
    margin-top: -11vw !important;
    font-size: 3.7vw;
    line-height: 18px;
  }
  
  .home-columns-two_title_bottom {
    position: absolute;
    margin-left: 20vw;
    margin-top: -6vw !important;
    margin-top: 5vw;
    font-size: 3.7vw;
    line-height: 18px;
  }
  
  .home-columns-title {
    position: absolute;
    margin-left: 20vw;
    margin-top: -12vw !important;
    font-size: 3.7vw;
    width: 4vw;
    line-height: 5vw;
  }
  
  .home-columns-description {
    margin-left: 20vw;
    font-size: 3vw;
    margin-top: 0vw !important;
    line-height: 4.5vw;
    color: #bebebe
  }
  

  .home-columns-images {
    width: 17%;
  }



    .home-columns-two_title {
    margin-left: 0vw;
    margin-top: 2vw !important;
    font-size: 2vw;
    line-height: 18px;
  }
  
  .home-columns-two_title_bottom {
    margin-left: 0vw;
    margin-top: 5vw !important;
    margin-top: 5vw;
    font-size: 2vw;
    line-height: 1vw;
  }
  
  .home-columns-title {
    margin-left: 0vw;
    margin-top: 4vw !important;
    font-size: 2vw;
    width: 20vw;
    line-height: 2vw;
  }
  
  .home-columns-description {
    margin-left: 0vw;
    font-size: 1.7vw;
    margin-top: 8vw !important;
    line-height: 3vw;
    color: #bebebe
  }

  .home-columns-images {
    width: 20%;
  }


/* 
  .home-columns-two_title {
    padding-top: 8%;
    padding-right: 20px;
    font-size: 14px;
    line-height: 18px;
  }

  .home-columns-two_title_bottom {
    padding-bottom: 10%;
    font-size: 14px;
    line-height: 18px;
  }

  .home-columns-title {
    padding-top: 8%;
    padding-bottom: 10%;
    padding-right: 20px;
    font-size: 14px;
    line-height: 18px;
  }

  .home-columns-description {
    font-size: 10px;
    line-height: 15px;
    color: #707070;
    padding-right: 20px;
  } */

  .text-background-orange {
    font-size: 1.6vw;
    font-weight: 600;
    padding: 3vw 10vw;
    text-align: right;
    line-height: 2vw;
  }

  .text-background-orange > p {
    width: auto;
    margin-left: 16vw;
  }

  .services-padding {
    padding-left: 3%;
  }

  .services-columns-paddingBottom {
    padding-bottom: 10%;
  }
  
  .services-last_columns-paddingBottom {
    padding: 0 0 7% 3%;
  }

  .services-title-up {
    font-size: 3vw;
    padding: 8% 18% 0 3%;
    line-height: 4vw;
    letter-spacing: 2.5px;
  }

  .services-title {
    font-size: 3vw;
    padding: .3% 18% 0 2%;
    line-height: 4vw;
    letter-spacing: 5.5px;
  }

  .services-subtitle {
    font-size: 2vw;
    line-height: 4vw;
    padding: 1% 8% 15% 2%;
    letter-spacing: 3px;
  }

  /* .services-title {
    font-size: 25px;
    padding: 0% 10% 0 3%;
    line-height: 35px;
    letter-spacing: 2.5px;
  }

  .services-subtitle {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.4px;
    padding: 3% 0 7% 3%;
  }

  .services-columnTitle {
    padding: 8% 13% 8% 0;;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.4px;
    text-transform: capitalize;
  }

  .services-columnSubtitle {
    font-size: 10px;
    line-height: 15px;
    color: var(--secondary-color);
    padding: 0 40% 8% 0;
    height: 40%;
  }

  .services-link {
    font-size: 9px;
  } */


  .services-columnTitle {
    margin-left: 0vw;
    width: 20vw;
    margin-top: 2vw !important;
    font-size: 2vw;
    line-height: 2.5vw;
    font-weight: 900;
  }
  
  .services-columnSubtitle {
    margin-left: 0vw;
    font-size: 1.5vw;
    margin-top: 9vw !important;
    line-height: 2.5vw;
    color: var(--secondary-color);
  }
  
  /* .services-columnTitle-margin {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.6px;
  } */
  
  .services-columns-paddingBottom {
    padding-bottom: 10%;
  }
  
  .services-link {
    margin-left: 0vw;
    color: var(--principal-color);
    text-decoration: none;
    font-size: 1.5vw;
  }

  .clients-image {
    width: 70%;
  }

  .dm-column_container {
    margin-bottom: 20%;
  }

  .dm-title_img {
    width: 5%;
  }
  
  .dm-title-padding_rigth {
    padding-right: 3%;
  }
  
  .dm-title-margin_bottom {
    margin-bottom: 10%;
  }
  
  .dm-column_title {
    font-size: 3vw;
    line-height: 3.8vw;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }
  
  .dm-column_description {
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: 1.1px;
    padding: 3% 0;
  }

  .dm-marginTop-space {
    margin-top: 75%;
  }

  .dm-columns-padding {
    padding-bottom: 8%;
  }

  /* .dm-columns-img {
    width: 13%;
    padding-bottom: 8%;
  }

  .dm-columns-title {
    font-size: 14px;
    line-height: 18px;
    padding: 2% 0 5% 0;
  }
  
  .dm-columns-subtitle {
    font-size: 11px;
    line-height: 20px;
    color: #bebebe;
    padding-right: 16%;
  } */

  
.dm-columns-img {
  width: 15%;
  padding-bottom: 8%;
}

.dm-columns-title {
  position: absolute;
  margin-left: 20vw;
  margin-top: -16vw !important;
  font-size: 3.5vw;
  font-weight: 900;
  line-height: 5vw;
  padding: 2% 0 5% 0;
}

.dm-columns-subtitle {
  margin-left: 20vw;
  font-size: 14px;
  line-height: 20px;
  color: #bebebe;
}



  .dsa-column_title {
    font-size: 25px;
    line-height: 35px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  .dsa-column_description{
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.1px;
    padding: 0 0;
  }

  .dsa-space-margin_top {
    margin-top: 55%;
  }

  .dsa-columns-image {
    width: 13%;
   }
   
   .dsa-columns-title {
     padding: 10% 3% 10%;
     font-size: 4vw;
     line-height: 25px;
     font-weight: 900;
   }
   
   .dsa-columns-text {
     font-size: 2vw;
     line-height: 3.3vw;
     width: 20vw;
     color: var(--secondary-color);
     padding: 5% 0 10% 0;
   }
   

  .dv-title_img {
    width: 4%;
  }

  .dv-title {
    font-size: 18px;
    line-height: 25px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }
  
  .dv-subtitle {
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.1px;
    padding: 0 0;
  }

  .dv-space-columns {
    margin-top: 50%;
    margin-bottom: 15%;
  }


  
  .dv-custom_dash-img {
    margin-top: 1vw;
    width: 18%;
  }
  
  .dv-custom_dash-title {
    position: absolute;
    margin-left: 0vw;
    margin-top: 0vw !important;
    font-size: 2vw;
    line-height: 2.4vw;
    padding: 8% 0;
  }
  
  .dv-custom_dash-subtitle {
    margin-top:10vw !important;
    font-size: 1.7vw;
    width: 25vw;
    position: absolute;
    margin-left: 0vw;
    line-height: 2vw;
    color: #bebebe;
    padding-bottom: 10%;
  }
  
  .imageVisual{
    margin-top: 0vw;
  }
  
  
  
  .dl-space-margin_top {
    margin-top: 45%;
  }

  .dl-subtitle-text {
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 1.4px;
    text-align: justify;
  }

  .dl-subtitle-db {
    padding: 10% 5%;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.1px;
  }

  .dl-elements-space {
    padding: 15% 0;
  }

  .dl-title-lists {
    font-size: 20px;
    line-height: 35px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5%;
  }

  .dl-number {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
  .dl-number-title {
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 1.5px;
  }
  
  .dl-number-description {
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 1.4px;
  }

  .contact-title {
    font-size: 20px;
    line-height: 35px;
    letter-spacing: 2px;
    text-transform: uppercase;
    padding: 18% 0;
  }

  .contact-description {
    padding: 19%;
    width: 47vw;
    margin-left: -6vw;
    font-size: 1.7vw;
    line-height: 19px;
    letter-spacing: 1.4px;
    padding-bottom: 12%;
  }
  
  /* .titleHeader-contac {
    display: flex;
  }

  .titleHeader-contac-block {
    display: none;
  } */

  .home-padding {
    padding: 5% 20% 25% 0;
  }

  .title-columns {
    font-size: 5.5vw;
    line-height: 7vw;
    text-align: center;
  }

  .container-bar-orange {
    position: absolute;
    overflow: hidden;
    left: 26%;
    top: auto;
    z-index: 2;
  }

  .marginTop-space {
    margin-top: 70%;
  }

  
  .clients-image {
    width: 70%;
  }
/* 
  .conect {
    position: absolute;
    bottom: auto;
    z-index: 1;
    left: 11vw;
    right: 15vw;
  }
  
  .conect-background {
    background-color: var(--principal-color);
    padding: 6% 5%;
  }
  
  .conect-text {
    font-size: 2vw;
    line-height: 3vw;
    text-transform: uppercase;
  }
  
  .conect-text-bold {
    font-size: 2.5vw;
    line-height: 4vw;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  
  .conect-text-description {
    font-size: 1.5vw;
    width: 30vw;
    line-height: 1.5vw;
    padding: 6% 0 3% 0;
  }
  
  .conect-button-width {
    height: 3vw;
    width: 100%;
    font-size: 1.1vw;
    line-height: 8px;
    letter-spacing: 0.3px;
    border-radius: 0 !important;
  }
  
  .conect-input-margins {
    margin: 2% 0;
    height: 3vw;
  }

  .conect-input-margins[type="text"]{
    font-size: 1.1vw;
  } */



  .conect {
    position: absolute;
    bottom: auto;
    z-index: 1;
    left: 16vw;
    right: 23vw;
  }
  
  .conect-background {
    background-color: var(--principal-color);
    padding: 9% 5%;
  }
  .conect-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .conect-text {
    font-size: 4vw;
    width: 100%;
    line-height: 5vw;
    text-transform: uppercase;
  }
  
  .conect-text-bold {
    font-size: 4vw;
    line-height: 5vw;
    width: 50vw;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  
  .conect-text-description {
    font-size: 3vw;
    line-height: 4vw;
    width: 100%;
    padding: 2% 0 3% 0;
  }
  
  .conect-button-width {
    height: 6vw;
    width: 100%;
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.3px;
    border-radius: 0 !important;
  }
  
  .conect-input-margins {
    height: 4vw;
    margin: 3% 0;
  }

  .title-section-divider {
    margin-top: 0vw;
    border-left: 1px solid var(--principal-color);
    padding-left: 1.2vw;
    line-height: 4.35vw;
    letter-spacing: 2px;
    font-weight: 600;
  }
  
  .title-section {
    color: var(--principal-color);
    font-size: 3vw;
    line-height: 27px;
    letter-spacing: 2px;
  }
  

  .groupLink{
    margin-top: 10vw;
    display: grid;
    grid-template-columns: 17vw 17vw 17vw 17vw;
    row-gap: 5vw;
    margin-bottom: 10vw;
  }
  
  .groupLinkIndividual{
    display: flex;
    flex-direction: column;
  }
  .groupLinkIndividual > img{
    margin-bottom: 1vw;
    width: 15%;
  }
  
  .groupLinkIndividual > p{
    font-size: 1.3vw;
    width: 13vw;
  }
  .imageVisualGif{
    z-index: 3;
    position: absolute;
    margin-top: -3vw;
    width: 35vw;
  }
  .text-orange{
    font-size: 1.3vw;
    line-height: 2.5vw;
    width: 50vw;
    margin-left: 17vw;
  }


  .incrementaModel{
    padding: 1% -20% 0% 100%;
    width: 80vw;
    display: flex;
    box-shadow: 0px 705px 00px 300px #f6f6f6;
    -webkit-box-shadow: 0px 705px 00px 300px #f6f6f6;
    -moz-box-shadow: 0px 705px 00px 300px #f6f6f6;
  }
  .ourModel{
    display: flex;
    flex-direction: column;
    margin-left: -7vw;
    width: 85vw;
  }

  .marginItems{
    height: 30vw;
  }
  
  .agroupItemsScience{
    margin-top: 2vw;
    padding: 0% 0% 0%;
  }
  
  .containerItemsScience{
    padding: 4% 10% 10%;
    display: grid;
    grid-template-columns: 35vw 40vw;
  }
  
  .ItemsScienceTitle{
    margin-top: 5vw;
    font-size: 3vw;
  }
  
  .marginItemsFinal{
    height: 7vw;
  }


  .contact-input-message {
    border: none;
    height: 8vw;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }

  .contact-input {
    height: 2vw;
    border: none;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }

  .contact-input[type=text]{
    font-family: "Avenir Next Medium";
     font-size: 1vw;
    width: 100%;
     padding: 12px 20px;
     margin: 8px 0;
     box-sizing: border-box;
 }

 .contact-input-message[type=text]{
  font-family: "Avenir Next Medium";
   font-size: 1vw;
  width: 100%;
   padding: 12px 20px;
   margin: 8px 0;
   box-sizing: border-box;
}

.contact-button-width {
  color: #fff;
  font-size: 1.6vw;
  width: 40%;
  height: 3.5vw;
  background-color: #f15223;
  border: none;
  border-radius: 0;
}

}














































@media (max-width: 575px) {
  .clients-wrapper {
    margin: 10px 0;
  }

  .marginTop-space {
    margin-top: 100%;
  }

  .titleHeader-contac {
    display: flex;
    width: 17vw;
    margin-top: -6vw;
    margin-left: 50vw;

  }
  .titleHeader-contac-block {
  display: none;
  }

  .title-section-divider {
    border-left: 1px solid var(--principal-color);
    padding-left: 1.2vw;
    line-height: 8.35vw;
    letter-spacing: 2px;
    font-weight: 600;
  }

  .home-columns-two_title {
    position: absolute;
    margin-left: 20vw;
    margin-top: -11vw !important;
    font-size: 3.7vw;
    line-height: 18px;
  }
  
  .home-columns-two_title_bottom {
    position: absolute;
    margin-left: 20vw;
    margin-top: -6vw !important;
    margin-top: 5vw;
    font-size: 3.7vw;
    line-height: 18px;
  }
  
  .home-columns-title {
    position: absolute;
    margin-left: 20vw;
    margin-top: -12vw !important;
    font-size: 3.7vw;
    width: 4vw;
    line-height: 5vw;
  }
  
  .home-columns-description {
    margin-left: 20vw;
    font-size: 3vw;
    margin-top: 0vw !important;
    line-height: 4.5vw;
    color: #bebebe
  }

  .home-columns-images {
    width: 17%;
  }
  


  .services-columnTitle {
    position: absolute;
    margin-left: 20vw;
    width: 30vw;
    margin-top: -9vw !important;
    font-size: 3.7vw;
    line-height: 4vw;
    font-weight: 900;
    text-transform: uppercase;
  }
  
  .services-columnSubtitle {
    margin-left: 20vw;
    font-size: 3vw;
    margin-top: 3vw !important;
    line-height: 4.5vw;
    color: var(--secondary-color);
  }
  
  /* .services-columnTitle-margin {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.6px;
  } */
  
  .services-columns-paddingBottom {
    padding-bottom: 10%;
  }
  
  .services-link {
    margin-left: 20vw;
    color: var(--principal-color);
    text-decoration: none;
    font-size: 3vw;
  }

  .title-background-orange {
    font-size: 3vw;
    line-height: 4vw;
    letter-spacing: 3.4px;
    margin-right: 15%;
  }

  .conect {
    position: absolute;
    bottom: auto;
    z-index: 1;
    left: 16vw;
    right: 23vw;
  }
  
  .conect-background {
    background-color: var(--principal-color);
    padding: 9% 5%;
  }
  .conect-content {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .conect-text {
    font-size: 4vw;
    width: 100%;
    line-height: 5vw;
    text-transform: uppercase;
  }
  
  .conect-text-bold {
    font-size: 4vw;
    line-height: 5vw;
    width: 50vw;
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  
  .conect-text-description {
    font-size: 3vw;
    line-height: 4vw;
    width: 100%;
    padding: 2% 0 3% 0;
  }
  
  .conect-button-width {
    height: 6vw;
    width: 100%;
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.3px;
    border-radius: 0 !important;
  }
  
  .conect-input-margins {
    height: 4vw;
    margin: 3% 0;
  }

  
  .about-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 100vw;
    left: 0;
    background-position: center;
    background-size: 140%;
    background-repeat: no-repeat;
    opacity: .3;
    z-index: 2;
  }


  .dv-custom_dash-img {
    width: 13%;
  }
  
  .dv-custom_dash-title {
    position: absolute;
    margin-left: 20vw;
    margin-top: -15vw !important;
    font-size: 18px;
    line-height: 5vw;
    padding: 8% 0;
  }
  
  .dv-custom_dash-subtitle {
    margin-top: 5vw !important;
    font-size: 3.3vw;
    width: 45vw;
    position: absolute;
    margin-left: 20vw;
    line-height: 4vw;
    color: #bebebe;
    padding-bottom: 10%;
  }
  
  .imageVisual{
    margin-top: 40vw;
    margin-bottom: 10vw;
  }


  .groupLink{
    margin-top: 10vw;
    margin-left: 7vw;
    display: grid;
    grid-template-columns: 33vw 33vw;
    row-gap: 5vw;
    margin-bottom: 10vw;
  }
  
  .groupLinkIndividual{
    display: flex;
    flex-direction: column;
    margin-bottom: 3vw;

  }
  .groupLinkIndividual > img{
    margin-bottom: 1vw;
    width: 25%;
  }
  
  .groupLinkIndividual > p{
    font-size: 3.3vw;
    width: 23vw;
  }

  .incrementaModel{
    padding: 1% -20% 0% 100%;
    width: 83vw;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 705px 00px 300px #f6f6f6;
    -webkit-box-shadow: 0px 705px 00px 300px #f6f6f6;
    -moz-box-shadow: 0px 705px 00px 300px #f6f6f6;
  }
  .ourModel{
    margin-top: -10vw;
    display: flex;
    flex-direction: column;
    margin-left: -7vw;
    width: 85vw;
  }

  .contact-description {
    padding: 0%;
    width: 70vw;
    margin-left: 0vw;
    font-size: 4vw;
    line-height: 5vw;
    letter-spacing: 1.4px;
    padding-bottom: 12%;
  }


  .contact-input-message {
    border: none;
    height: 20vw;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }
  
  .contact-input {
    height: 6vw;
    border: none;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }
  
  .contact-input[type=text]{
    font-family: "Avenir Next Medium";
     font-size: 2.4vw;
    width: 100%;
     padding: 12px 20px;
     margin: 8px 0;
     box-sizing: border-box;
  }
  
  .contact-input-message[type=text]{
  font-family: "Avenir Next Medium";
   font-size: 2.4vw;
  width: 100%;
   padding: 12px 20px;
   margin: 8px 0;
   box-sizing: border-box;
  }

  .contact-button-width {
    color: #fff;
    font-size: 1.8vw;
    width: 40%;
    height: 5vw;
    background-color: #f15223;
    border: none;
    border-radius: 0;
  }
  
  
}

















































@media (min-width: 900px) {
  .titleHeader-link {
    color: var(--helper-color);
    text-decoration: none;
    font-size: 16px;
    width: fit-content;
    line-height: 22px;
    letter-spacing: 1.6px;
  }


  .titleHeader-contac {
    display: flex !important;
    width: 17vw;
    margin-top: 0vw;
    margin-left: -10vw;
  }
  .titleHeader-contac-block {
    display: none !important;
  }

  .title-section-divider {
    border-left: 1px solid var(--principal-color);
    padding-left: 1.2vw;
    line-height: 5.35vw;
    letter-spacing: 2px;
    font-weight: 600;
  }
  
  .title-section {
    font-size: 25px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: 2.5px;
  }

  .title-background-orange {
    font-size: 2.5vw;
    line-height: 46px;
    letter-spacing: 3.4px;
    margin-right: 15%;
  }

  .titleHeader-background {
    padding: 1vw 1vw;
  }

  .home-description-section {
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: 2px;
    padding-top: 5rem;
    width: 70vw;
  }

  .description-section {
    font-size: 2vw;
    width: 65vw;
    font-weight: 600;
    line-height: 3vw;
    letter-spacing: 3px;
    padding-top: 3rem;
  }

  .home-padding {
    padding: 5% 0% 25% 0;
  }

  .titleHeader-link {
    font-size: 1rem;
    width: fit-content;
    line-height: 22px;
    letter-spacing: 1.6px;
  }

  /* .home-columns-images {
    width: 15%;
  } */

  .home-columns-two_title {
    margin-left: 0vw;
    margin-top: 2vw !important;
    font-size: 1.7vw;
    line-height: 18px;
  }
  
  .home-columns-two_title_bottom {
    margin-left: 0vw;
    margin-top: 5vw !important;
    margin-top: 5vw;
    font-size: 1.7vw;
    line-height: 1vw;
  }
  
  .home-columns-title {
    margin-left: 0vw;
    margin-top: 4vw !important;
    font-size: 1.7vw;
    width: 20vw;
    line-height: 2vw;
  }
  
  .home-columns-description {
    margin-left: 0vw;
    font-size: 1.5vw;
    width: 20vw;
    margin-top: 8vw !important;
    line-height: 3vw;
    color: #bebebe
  }

  .home-columns-images {
    width: 20%;
  }

  .title-columns {
    font-size: 3.2vw;
    text-align: left;
    line-height: 45px;
  }

  .text-background-orange {
    font-size: 1.6vw;
    font-weight: 600;
    padding: 3vw 5vw;
    text-align: right;
    line-height: 2.5vw;
    letter-spacing: 1.4px;
  }

  .text-background-orange > p {
    width: auto;
    margin-left: 14vw;
  }

  .home-columns-paddingBottom {
    padding-bottom: 0%;
  }

  .marginTop-space {
    margin-top: 40%;
  }

  .services-title-up {
    font-size: 2.4vw;
    padding: 8% 10% 0 2%;
    line-height: 3vw;
    letter-spacing: 3.5px;
  }

  .services-title {
    font-size: 2.4vw;
    padding: .3% 18% 0 2%;
    line-height: 3vw;
    letter-spacing: 5.5px;
  }

  .services-subtitle {
    font-size: 1.5vw;
    line-height: 3vw;
    padding: 1% 8% 7% 2%;
    letter-spacing: 3px;
  }

  /* .services-title {
    font-size: 35px;
    padding: 0% 20% 0 0%;
    line-height: 45px;
    letter-spacing: 3.5px;
  }

  .services-subtitle {
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 2px;
    padding: 3% 0 7% 0%;
  }

  .services-columnTitle {
    padding: 8% 13% 8% 0;;
    font-size: 20px;
    font-weight: 900;
    line-height: 24px;
    letter-spacing: 1.4px;
    text-transform: capitalize;
  }

  .services-columnSubtitle {
    font-size: 16px;
    line-height: 22px;
    color: var(--secondary-color);
    padding: 0 40% 8% 0;
  } */

  .services-columnTitle {
    margin-left: 0vw;
    width: 17vw;
    margin-top: 2vw !important;
    font-size: 1.7vw;
    line-height: 2.5vw;
    font-weight: 900;
  }
  
  .services-columnSubtitle {
    margin-left: 0vw;
    font-size: 1.5vw;
    width: 16vw;
    margin-top: 9vw !important;
    line-height: 2.5vw;
    color: var(--secondary-color);
  }
  
  /* .services-columnTitle-margin {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.6px;
  } */
  
  .services-columns-paddingBottom {
    padding-bottom: 10%;
  }
  
  .services-link {
    margin-left: 0vw;
    color: var(--principal-color);
    text-decoration: none;
    font-size: 1.5vw;
  }


  .services-padding {
    padding-left: 0%;
  }
  

  /* .services-link {
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 1.4px;
  } */

  .dm-title_img {
    width: 4%;
  }
  
  .dm-title-padding_rigth {
    padding-right: 3%;
  }
  
  .dm-title-margin_bottom {
    margin-bottom: 10%;
  }
  
  .dm-column_title {
    font-size: 2.8vw;
    line-height: 3.5vw;
    letter-spacing: 2.5px;
    text-transform: uppercase;
  }
  
  .dm-column_description {
    font-size: 2vw;
    line-height: 2.5vw;
    letter-spacing: 1.6px;
    padding: 0 0;
  }

  .dm-marginTop-space {
    margin-top: 58%;
  }
/* 
  .dm-columns-img {
    width: 10%;
    padding-bottom: 8%;
  }

  .dm-columns-title {
    font-size: 2vw;
    line-height: 24px;
    padding: 2% 0 5% 0;
  }
  
  .dm-columns-subtitle {
    font-size: 16px;
    line-height: 22px;
    color: #707070;
    padding-right: 16%;
  } */

  .dm-columns-img {
    margin-top: 2vw;
    width: 16%;
    padding-bottom: 8%;
  }
  
  .dm-columns-title {
    /* position: absolute; */
    margin-left: 0vw;
    margin-top: 1vw !important;
    font-size: 2vw;
    font-weight: 900;
    line-height: 2.5vw;
    padding: 2% 0 5% 0;
  }
  
  .dm-columns-subtitle {
    margin-left: 0vw;
    margin-top: 10vw !important;
    font-size: 1.7vw;
    width: 17vw;
    line-height: 2.3vw;
    color: #bebebe;
  }

  .dsa-column_title {
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  .dsa-column_description{
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.6px;
    padding: 0 0;
  }

  .dsa-columns-image {
    width: 13%;
   }

  .dsa-space-margin_top {
    margin-top: 45%;
  }

  .dsa-columns-title {
    font-size: 1.7vw;
    line-height: 24px;
    padding: 5% 0 5% 0;
  }

  .dsa-columns-text {
    font-size: 1.5vw;
    line-height: 22px;
    color: var(--secondary-color);
    padding: 5% 0 10% 0;
  }

  .dv-title_img {
    width: 4%;
  }

  .dv-title {
    font-size: 35px;
    line-height: 45px;
    letter-spacing: 1.8px;
    text-transform: uppercase;
  }
  
  .dv-subtitle {
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.6px;
    padding: 0 0;
  }

  .dv-space-columns {
    margin-top: 40%;
    margin-bottom: 15%;
  }

  .dv-custom_dash-img {
    margin-top: 1vw;
    width: 18%;
  }
  
  .dv-custom_dash-title {
    position: absolute;
    margin-left: 0vw;
    margin-top: 0vw !important;
    font-size: 2vw;
    line-height: 2.4vw;
    padding: 8% 0;
  }
  
  .dv-custom_dash-subtitle {
    margin-top:10vw !important;
    font-size: 1.7vw;
    width: 25vw;
    position: absolute;
    margin-left: 0vw;
    line-height: 2vw;
    color: #bebebe;
    padding-bottom: 10%;
  }
  
  .imageVisual{
    margin-top: 0vw;
  }

  .dl-space-margin_top {
    margin-top: 40%;
  }

  .dl-subtitle-text {
    font-size: 18px;
    line-height: 16px;
    letter-spacing: 1.8px;
    text-align: justify;
  }

  .dl-subtitle-db {
    padding: 10% 5%;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.6px;
  }

  .dl-elements-space {
    padding: 22% 0;
  }

  .dl-title-lists {
    font-size: 35px;
    line-height: 45px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5%;
  }

  .dl-number {
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 2px;
  }
  .dl-number-title {
    font-size: 20px;
    line-height: 27px;
    letter-spacing: 2px;
  }
  
  .dl-number-description {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 1.6px;
  }

  .contact-title {
    font-size: 30px;
    line-height: 45px;
    letter-spacing: 3px;
    text-transform: uppercase;
    padding: 18% 0;
  }

  .contact-description {
    padding: 18%;
    width: 45vw;
    margin-left: -6vw;
    font-size: 1.2vw;
    line-height: 2vw;
    letter-spacing: 1.8px;
    padding-bottom: 12%;
  }
  .clients {
    padding: 8% 0 0% 0;;
  }
  .dm-column_container .col-sm-8 {
    display: flex;
    align-items: center;
  }

  .conect {
    position: absolute;
    bottom: auto;
    z-index: 1;
    left: 11vw;
    right: 16vw;
  }
  
  .conect-background {
    background-color: var(--principal-color);
    padding: 9% 5%;
  }
  .conect-content {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .conect-text {
    font-size: 1.7vw;
    width: 20vw;
    line-height: 2vw;
    text-transform: uppercase;
  }
  
  .conect-text-bold {
    font-size: 1.7vw;
    line-height: 3vw;
    width: 20vw;
    /* width: 100vw; */
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  
  .conect-text-description {
    font-size: 1.7vw;
    line-height: 2vw;
    width: 10vw;
    width: 100%;
    padding: 2% 0 3% 0;
  }
  
  .conect-button-width {
    height: 2.7vw;
    width: 100%;
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.3px;
    border-radius: 0 !important;
  }
  
  .conect-input-margins {
    height: 2.7vw;
    margin: 3% 0;
  }

  
  .conect-input-margins[type="text"]{
    font-size: 1.1vw;
  }

  .about-bg {
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: -20vw;
    left: 0;
    background-position: center;
    background-size: 140%;
    background-repeat: no-repeat;
    opacity: .3;
    z-index: 2;
  }

  .groupLink{
    margin-top: 10vw;
    display: grid;
    grid-template-columns: 17vw 17vw 17vw 17vw;
    row-gap: 5vw;
    margin-bottom: 10vw;
  }
  
  .groupLinkIndividual{
    display: flex;
    flex-direction: column;
  }
  .groupLinkIndividual > img{
    margin-bottom: 1vw;
    width: 15%;
  }
  
  .groupLinkIndividual > p{
    font-size: 1.3vw;
    width: 13vw;
  }

  .imageVisualGif{
    z-index: 3;
    position: absolute;
    margin-top: -3vw;
    width: 30vw;
  }
  .text-orange{
    width: 40vw;
    margin-left: 15vw;
  }

  .incrementaModel{
    padding: 1% -20% 0% 100%;
    width: 80vw;
    display: flex;
    /* box-shadow: 0px 65px 0px 0px rgba(190,190,190,0.51); */
    -webkit-box-shadow: 0px 705px 00px 300px #f6f6f6;
    /* -moz-box-shadow: 0px 65px 0px 0px rgba(190,190,190,0.51); */
  }
  .ourModel{
    display: flex;
    flex-direction: column;
    margin-left: -7vw;
    width: 85vw;
  }

  .marginItems{
    height: 18vw;
  }

  .agroupItemsScience{
    margin-top: 2vw;
    padding: 0% 0% 0%;
  }

  .containerItemsScience{
    padding: 4% 0;
    display: grid;
    grid-template-columns: 25vw 25vw 25vw;
  }

  .ItemsScienceTitle{
    margin-top: 5vw;
    font-size: 1.8vw;
  }
  
  .marginItemsFinal{
    height: 7vw;
  }

  .contact-input-message {
    border: none;
    height: 8vw;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }

  .contact-input {
    height: 2vw;
    border: none;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }

  .contact-input[type=text]{
    font-family: "Avenir Next Medium";
     font-size: 1vw;
    width: 100%;
     padding: 12px 20px;
     margin: 8px 0;
     box-sizing: border-box;
 }

 .contact-input-message[type=text]{
  font-family: "Avenir Next Medium";
   font-size: 1vw;
  width: 100%;
   padding: 12px 20px;
   margin: 8px 0;
   box-sizing: border-box;
}

.contact-button-width {
  color: #fff;
  width: 30%;
  height: 2.5vw;
  background-color: #f15223;
  border: none;
  border-radius: 0;
}
}









































@media (min-width: 1500px) {
  .titleHeader-link {
    color: var(--helper-color);
    text-decoration: none;
    font-size: 18px;
    width: fit-content;
    line-height: 25px;
    letter-spacing: 1.8px;
  }

  .titleHeader-contac {
    display: flex !important;
    width: 10vw;
    margin-top: 0vw;
    margin-left: 0vw;
  }
  .titleHeader-contac-block {
    display: none !important;
  }

  .title-background-orange {
    font-size: 2.5vw;
    padding: 2.5px 7px;
    line-height: 60px;
    letter-spacing: 4.4px;
    width: fit-content;
  }

  .titleHeader-padding {
    padding-bottom: 10%;
  }

  .home-description-section {
    width: 70vw;
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: 5px;
    padding-top: 5rem;
  }

  .description-section {
    font-size: 2.3vw;
    width: 65vw;
    font-weight: 600;
    line-height: 3.3vw;
    letter-spacing: 5px;
    padding-top: 5rem;
  }

  .home-padding {
    padding: 5% 12% 22% 0;
  }

  .title-columns {
    font-size: 3.2vw;
    text-align: left;
    line-height: 65px;
  }

  .home-columns-two_title {
    margin-left: 0vw;
    margin-top: 2vw !important;
    font-size: 2vw;
    line-height: 18px;
  }
  
  .home-columns-two_title_bottom {
    margin-left: 0vw;
    margin-top: 5vw !important;
    margin-top: 5vw;
    font-size: 2vw;
    line-height: 1vw;
  }
  
  .home-columns-title {
    margin-left: 0vw;
    margin-top: 4vw !important;
    font-size: 2vw;
    width: 20vw;
    line-height: 2vw;
  }
  
  .home-columns-description {
    margin-left: 0vw;
    width: 19vw;
    font-size: 1.5vw;
    margin-top: 8vw !important;
    line-height: 3vw;
    color: #bebebe
  }

  .home-columns-images {
    width: 20%;
  }

  .text-background-orange {
    font-size: 1.5vw;
    font-weight: 600;
    padding: 40px 140px;
    text-align: right;
    line-height: 2.5vw;
    letter-spacing: 3px;
    text-align: right;
  }

  .marginTop-space {
    margin-top: 35%;
  }

  .title-section {
    font-size: 45px;
    font-weight: 600;
    line-height: 61px;
    letter-spacing: 4.5px;
  }

  .services-title {
    font-size: 3vw;
    padding: .3% 18% 0 2%;
    line-height: 5vw;
    letter-spacing: 5.5px;
  }

  .services-subtitle {
    font-size: 2vw;
    line-height: 3vw;
    padding: 1% 8% 7% 2%;
    letter-spacing: 3px;
  }

  /* .services-title {
    font-size: 3vw;
    padding: .3% 18% 0 2%;
    line-height: 75px;
    letter-spacing: 5.5px;
  }

  .services-subtitle {
    font-size: 30px;
    line-height: 41px;
    padding: 1% 8% 7% 2%;
    letter-spacing: 3px;
  }

  .services-columnTitle {
    padding: 8% 30% 8% 0;;
    font-size: 30px;
    font-weight: 900;
    line-height: 35px;
    letter-spacing: 2.4px;
    text-transform: capitalize;
  }

  .services-columnSubtitle {
    font-size: 20px;
    line-height: 25px;
    color: var(--secondary-color);
    padding: 0 40% 8% 0;
  }
   */



   .services-columnTitle {
    margin-left: 0vw;
    width: 20vw;
    margin-top: 2vw !important;
    font-size: 2vw;
    line-height: 2.5vw;
    font-weight: 900;
  }
  
  .services-columnSubtitle {
    margin-left: 0vw;
    font-size: 1.5vw;
    margin-top: 9vw !important;
    line-height: 2.5vw;
    color: var(--secondary-color);
  }
  
  /* .services-columnTitle-margin {
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 1.6px;
  } */
  
  .services-columns-paddingBottom {
    padding-bottom: 10%;
  }
  
  .services-link {
    margin-left: 0vw;
    color: var(--principal-color);
    text-decoration: none;
    font-size: 1.5vw;
  }


  .dm-column_title {
    font-size: 3vw;
    line-height: 4vw;
  }
  
  .dm-column_description {
    font-size: 2.2vw;
    line-height: 3vw;
    letter-spacing: 3.6px;
    padding: 0 0;
  }

  .dm-marginTop-space {
    margin-top: 50%;
  }

  /* .dm-columns-img {
    width: 10%;
    padding-bottom: 8%;
  }

  .dm-columns-title {
    font-size: 30px;
    line-height: 35px;
    padding: 2% 0 5% 0;
  }
  
  .dm-columns-subtitle {
    font-size: 20px;
    line-height: 25px;
    color: #707070;
    padding-right: 16%;
  } */


  .dm-columns-img {
    margin-top: 2vw;
    width: 15%;
    padding-bottom: 8%;
  }
  
  .dm-columns-title {
    /* position: absolute; */
    margin-left: 0vw;
    margin-top: 0vw !important;
    font-size: 1.8vw;
    font-weight: 900;
    line-height: 2.5vw;
    padding: 2% 0 5% 0;
  }
  
  .dm-columns-subtitle {
    margin-left: 0vw;
    margin-top: 7vw !important;
    font-size: 1.5vw;
    width: 17vw;
    line-height: 3vw;
    color: #bebebe;
  }

  .dsa-column_title {
    font-size: 55px;
    line-height: 65px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  .dsa-column_description{
    font-size: 36px;
    line-height: 49px;
    letter-spacing: 3.6px;
    padding: 0 0;
  }

  .dsa-space-margin_top {
    margin-top: 55%;
  }

  .dsa-columns-title {
    font-size: 30px;
    line-height: 35px;
    padding: 5% 0 5% 0;
  }

  .dsa-columns-image {
    width: 15%;
  }
  
  .dsa-columns-text {
    font-size: 1.7;
    line-height: 25px;
    color: var(--secondary-color);
    width: 15vw;
    padding: 5% 0 10% 0;
  }

  .dv-title-padding_rigth {
    padding-right: 3%;
}

  .dv-title_img {
    width: 3%;
  }

  .dv-title {
    font-size: 55px;
    line-height: 65px;
    letter-spacing: 2.4px;
    text-transform: uppercase;
  }
  
  .dv-subtitle {
    font-size: 36px;
    line-height: 49px;
    letter-spacing: 2.6px;
    padding: 0 0;
  }

  .dv-space-columns {
    margin-top: 35%;
    margin-bottom: 10%;
  }
  
  .dv-custom_dash-img {
    margin-top: 1vw;
    width: 18%;
  }
  
  .dv-custom_dash-title {
    position: absolute;
    margin-left: 0vw;
    margin-top: 0vw !important;
    font-size: 2vw;
    line-height: 2.4vw;
    padding: 8% 0;
  }
  
  .dv-custom_dash-subtitle {
    margin-top:10vw !important;
    font-size: 1.7vw;
    width: 25vw;
    position: absolute;
    margin-left: 0vw;
    line-height: 2vw;
    color: #bebebe;
    padding-bottom: 10%;
  }
  
  .imageVisual{
    margin-top: 0vw;
  }

  .dl-space-margin_top {
    margin-top: 35%;
  }

  .dl-subtitle-text {
    font-size: 30px;
    line-height: 32px;
    letter-spacing: 3px;
    text-align: justify;
  }

  .dl-subtitle-db {
    padding: 10% 5%;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 1.6px;
  }

  .dl-elements-space {
    padding: 7% 0;
  }

  .dl-title-lists {
    font-size: 2vw;
    line-height: 3vw;
    letter-spacing: 5.5px;
    font-weight: 600;
    text-transform: uppercase;
    padding: 5%;
  }

  .dl-number {
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 3px;
  }
  .dl-number-title {
    font-size: 30px;
    line-height: 41px;
    letter-spacing: 3px;
  }
  
  .dl-number-description {
    font-size: 22px;
    line-height: 25px;
    letter-spacing: 2.2px;
  }

  .contact-title {
    font-size: 50px;
    line-height: 65px;
    letter-spacing: 5px;
    text-transform: uppercase;
    padding: 18% 0;
  }

  .contact-description {
    padding: 20%;
    width: 50vw;
    margin-left: -7vw;
    font-size: 1.5vw;
    line-height: 2.3vw;
    letter-spacing: 2.2px;
    padding-bottom: 12%;
  }


  .conect {
    position: absolute;
    bottom: auto;
    z-index: 1;
    left: 11vw;
    right: 16vw;
  }
  
  .conect-background {
    background-color: var(--principal-color);
    padding: 9% 5%;
  }
  .conect-content {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  .conect-text {
    font-size: 1.7vw;
    width: 20vw;
    line-height: 2vw;
    text-transform: uppercase;
  }
  
  .conect-text-bold {
    font-size: 1.7vw;
    line-height: 3vw;
    width: 20vw;
    /* width: 100vw; */
    letter-spacing: 1.25px;
    text-transform: uppercase;
  }
  
  .conect-text-description {
    font-size: 1.7vw;
    line-height: 2vw;
    width: 10vw;
    width: 100%;
    padding: 2% 0 3% 0;
  }
  
  .conect-button-width {
    height: 2.7vw;
    width: 100%;
    font-size: 10px;
    line-height: 8px;
    letter-spacing: 0.3px;
    border-radius: 0 !important;
  }
  
  .conect-input-margins {
    height: 2.7vw;
    margin: 3% 0;
  }

  
  .conect-input-margins[type="text"]{
    font-size: 1.1vw;
  }

  .groupLink{
    margin-top: 10vw;
    display: grid;
    grid-template-columns: 17vw 17vw 17vw 17vw;
    row-gap: 5vw;
    margin-bottom: 10vw;
  }
  
  .groupLinkIndividual{
    display: flex;
    flex-direction: column;
  }
  .groupLinkIndividual > img{
    margin-bottom: 1vw;
    width: 15%;
  }
  
  .groupLinkIndividual > p{
    font-size: 1.3vw;
    width: 13vw;
  }

  .imageVisualGif{
    z-index: 3;
    position: absolute;
    margin-top: -3vw;
    width: 30vw;
  }
  .text-orange{
    width: 40vw;
    margin-left: 15vw;
  }

  .incrementaModel{
    padding: 1% -20% 0% 100%;
    width: 80vw;
    display: flex;
    /* box-shadow: 0px 65px 0px 0px rgba(190,190,190,0.51); */
    -webkit-box-shadow: 0px 705px 00px 300px #f6f6f6;
    /* -moz-box-shadow: 0px 65px 0px 0px rgba(190,190,190,0.51); */
  }
  .ourModel{
    display: flex;
    flex-direction: column;
    margin-left: -7vw;
    width: 85vw;
  }

  .marginItems{
    height: 15vw;
  }

  .agroupItemsScience{
    margin-top: 2vw;
    padding: 0% 0% 0%;
  }

  .containerItemsScience{
    padding: 4% 0;
    display: grid;
    grid-template-columns: 25vw 25vw 25vw;
  }

  .contact-input-message {
    border: none;
    height: 8vw;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }

  .contact-input {
    height: 2vw;
    border: none;
    border-bottom: 1px solid;
    background: rgba(4, 4, 21, 0.03);
    margin: 2% 0;
  }

  .contact-input[type=text]{
    font-family: "Avenir Next Medium";
     font-size: 1vw;
    width: 100%;
     padding: 12px 20px;
     margin: 8px 0;
     box-sizing: border-box;
 }

 .contact-input-message[type=text]{
  font-family: "Avenir Next Medium";
   font-size: 1vw;
  width: 100%;
   padding: 12px 20px;
   margin: 8px 0;
   box-sizing: border-box;
}

.contact-button-width {
  color: #fff;
  font-size: 1vw;
  width: 30%;
  height: 2vw;
  background-color: #f15223;
  border: none;
  border-radius: 0;
}

}


